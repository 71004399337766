@import "src/scss/_variables";

.CustomTextField {
  display: flex;
  align-items: center;
  background: white;
  border: 0.1rem solid $grey400;
  border-radius: 4px;
  margin-bottom: 0.8rem;

  .CustomIcon {
    width: 2em;
    margin-left: 0.8rem;
    display: flex;
    justify-content: center;
  }

  .MuiTextField-root {
    width: 100%;
    background: transparent;

    .MuiInputLabel-root {
      font-size: 0.9rem;
    }
    .MuiInputBase-root {
      background: transparent;
    }
  }
}
