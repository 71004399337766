@import "src/scss/_variables";

.SearchBar {
  display: flex;
  align-self: center;
  align-items: flex-end;
  color: $grey500;
  border-radius: 0.2rem;
  width: 100%;
  align-items: center;

  &.box {
    padding: 0.3rem 0.5rem 0.2rem 0.8rem;
    background: $grey100;
  }

  &.search {
    padding: 0.2rem 0.5rem 0.2rem 1rem;
    background: white;
  }

  .pre-icon {
    margin-right: 0.7rem;
    display: flex;
    align-items: center;
  }

  .end-icon {
    display: flex;
    color: $grey700;
  }
  .search-bar__form-form {
    width: 100%;
    .search-input {
      width: 100%;
      input[type="search"]::-webkit-search-decoration,
      input[type="search"]::-webkit-search-cancel-button,
      input[type="search"]::-webkit-search-results-button,
      input[type="search"]::-webkit-search-results-decoration {
        -webkit-appearance: none;
      }
    }
  }
}
