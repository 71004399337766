@import "src/scss/_variables";

.LocationPickerModal {
  border-radius: 0 !important;

  .mx-4 {
    margin: 0 1rem;
  }

  .MuiDialog-paper {
    display: block;
    background-color: $grey300;
  }

  .searchbar-section {
    display: flex;
    align-items: center;
    padding: 0 1rem 0;
    margin: 0.8rem 0 0.5rem 0;

    .container-maps-search-bar {
      --maps-search-bar-width: 100%;
      display: flex;
      flex-direction: column;
      width: var(--maps-search-bar-width);
      padding: 0 1rem;
      position: relative;
      font-family: inherit;

      .container-search-autocomplete {
        position: absolute;
        z-index: 10;
        top: 2.2rem;
        left: 0.25rem;
        width: calc(var(--maps-search-bar-width) - 0.5rem);
        box-sizing: border-box;
        background: white;

        .item-autocomplete {
          padding: 0.6rem 1rem;

          &.selected {
            background-color: rgb(240, 240, 240);
            cursor: pointer;
          }

          &.not-selected {
            background-color: white;
            cursor: pointer;
          }

          .main-text-item-autocomplete {
            margin: 0;
            padding: 0;
          }
        }
      }
    }
    .auto-suggest-container {
      display: flex;
      width: 100%;
      .clear-btn {
        position: absolute;
        top: 7px;
        right: 8%;
      }
    }

    .react-autosuggest__container {
      position: relative;
      flex-grow: 1;
    }

    .react-autosuggest__input {
      width: 90%;
      height: 20px;
      padding: 10px 20px;
      font-family: OpenSansRegular;
      font-weight: 300;
      font-size: 16px;
      // border: 1px solid #aaa;
      border: none;
      border-radius: 4px;
    }

    .react-autosuggest__input--focused {
      outline: none;
    }

    .react-autosuggest__input--open {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .react-autosuggest__suggestions-container {
      display: none;
    }

    .react-autosuggest__suggestions-container--open {
      display: block;
      position: absolute;
      top: 51px;
      width: 95%;
      border: 1px solid #aaa;
      background-color: #fff;
      font-family: OpenSansRegular;
      font-weight: 300;
      font-size: 16px;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      z-index: 10;
    }

    .react-autosuggest__suggestions-list {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }

    .react-autosuggest__suggestion {
      cursor: pointer;
      padding: 10px 20px;
    }

    .react-autosuggest__suggestion--highlighted {
      background-color: #ddd;
    }
  }

  &.lg-dialog {
    .MuiDialog-paper {
      height: 780px;
      width: 50rem;
      max-width: 50rem;
    }
  }

  .section-title {
    text-align: left;
    margin-bottom: 0.5rem !important;
  }

  %shared-card {
    background-color: white;
    box-shadow: 0px 2px 10px -5px rgba(0, 0, 0, 0.35);
    text-align: left;
    margin-bottom: 0.8rem;
    cursor: pointer;

    .MuiCardContent-root {
      padding: 16px 1.3rem !important;
    }

    .title {
      font-weight: bold;
    }

    .address {
      color: $grey600;
    }
  }

  .current-location-card {
    @extend %shared-card;
    .icon-location {
      margin-top: 0.15rem;
      margin-right: 0.5rem;
      font-size: 1.2rem;
    }
  }

  .location-card {
    @extend %shared-card;
    &.disabled {
      cursor: inherit;
      .MuiTypography-root {
        color: $grey500 !important;
      }
    }

    .location-title-section {
      .distance {
        margin-bottom: 0.25rem;
      }
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.2rem;
    }

    .description {
      color: $grey700;
      margin-bottom: 0.5rem;
    }
    .status {
      text-transform: capitalize;
      font-weight: 500;
      color: $primaryLight;
    }
  }

  .btn-close-modal {
    color: $grey800;
    margin-left: -16px;
  }

  .MuiTypography-body1 {
    font-weight: 600;
    margin-bottom: 1rem;
  }
}
