// Global variables
$primary: #008cdb;
$primaryLight: #1e95da;
$primaryDark: #007abf;
$success: #009c6a;
$danger: #fa6d78;

$white: #FFFFFF;
$green: #00A530;
$grey100: #f5f6f9;
$grey200: #edf2f7;
$grey300: #e2e8f0;
$grey400: #cbd5e0;
$grey500: #a0aec0;
$grey600: #718096;
$grey700: #4a5568;
$grey800: #2d3748;
$grey900: #1a202c;
