@import "src/scss/_variables";

.ConfirmBookingModal {
  .MuiDialog-paper {
    width: 20rem;
    text-align: center;
    padding: 3.5rem 3rem;
    border-radius: 0 !important;

    @media only screen and (max-width: 960px) {
      padding: 2.5rem 2rem !important;
    }

    @media only screen and (max-width: 374px) {
      padding: 2rem 1rem !important;
    }

    .btn-close {
      color: $grey800;
      position: absolute;
      top: -12px;
      left: -12px;
    }

    .title {
      color: $grey800;
      font-size: 1.2rem;
      margin-bottom: 0.5rem;
      position: relative;
      text-align: left;
      padding-left: 2em;
    }

    .subtitle {
      margin-bottom: 1rem;
      text-align: left;
      margin-left: 2.4rem;
    }

    .checkbox-section {
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;
    }

    .MuiButton-contained {
      margin-bottom: 0.5rem;
    }
  }
}
