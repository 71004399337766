@import "src/scss/_variables";

.Marker {
  position: absolute;
}

.MuiTooltip-tooltipPlacementTop {
  background-color: white !important;
}
.MuiTooltip-tooltip {
  color: black !important;
}
.tooltip-container{
  .address-section{
    display: flex;
    .address-content{
      margin-left: 5px;
    }
  }
  .operating-hour-section{
    display: flex;
    margin-top: 5px;
    margin-bottom: 5px;
    .operating-hour-content{
      margin-left: 5px;
    }
  }
  .available{
    color: #35E34C;
  }
  .not-available{
    color: #FF0000;
  }
}