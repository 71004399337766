@import "src/scss/_variables";

.Background {
  position: relative;
  padding-top: 0.1rem;

  &.h-screen {
    min-height: 120vh;
  }
  &.content-center {
    display: flex;
    justify-content: center;
  }

  &.gradient {
    background: $primary;
    background: linear-gradient(42deg, $primary 0%, $success 100%);
  }
  &.white {
    background: $white;
    padding-top: 0px;
  }
  &.white-fixed {
    background: $white;
    padding-top: 0px;
    min-height: 600px;
  }
  &.primary {
    background: $primary;
    padding-top: 0px;
  }

  &.grey100 {
    background: $grey100;
    padding-bottom: 0.1rem;
  }
}
