@import "src/scss/_variables";

.Consent-Modal {
  background-color: $white;
  .MuiDialog-paper {
    border-radius: 0 !important;
    padding-left: 5px;
    padding-right: 5px;

    .MuiTypography-h5 {
      color: $grey800;
      font-size: 20px;
      position: relative;
      text-align: left;
    }

    .MuiTypography-body1 {
      font-weight: 600;
    }

    .MuiButton-contained {
      margin-bottom: 1rem;
    }
    .consent-header-container {
      display: flex;
      width: 100%;
      margin-top: 0.5rem;
      padding: 10px;
    }
    .consent-content-container {
      margin-top: 20px;
      padding-left: 20px;
      display: flex;
      flex-direction: column;
      min-height: 100vh;
      .logo {
        max-width: 200px;
      }
      .logo-text {
        margin-left: 10px;
        color: $grey500;
      }
      .consent-body {
        margin-left: 10px;
        margin-top: 20px;
        .consent-text-gp {
          display: flex;
          margin-bottom: 20px;
          .text-gp-column {
            display: flex;
            flex-direction: column;
            .confirm-icon {
              color: lightseagreen;
            }
            .header-text {
              font-size: 1.1rem;
              margin-left: 10px;
            }
            .body-text {
              margin-left: 10px;
              color: $grey500;
            }
          }
        }
      }
    }
    .consent-footer-container {
      margin: auto;
    }
  }
}
