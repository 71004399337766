@import "src/scss/_variables";

.Modal {
  background-color: $white;
  .MuiDialog-paper {
    width: 20rem;
    text-align: center;
    padding: 3.5rem 3rem;
    border-radius: 0 !important;

    @media only screen and (max-width: 960px) {
      padding: 2.5rem 2rem !important;
    }

    @media only screen and (max-width: 374px) {
      padding: 1.5rem 1.5rem !important;
    }

    img {
      width: 8rem;
      margin: auto;
      margin-bottom: 1.5rem;
    }

    .MuiTypography-h5 {
      color: $grey800;
      font-size: 1.2rem;
      margin-bottom: 1rem;
      // margin-left: 20px;
      position: relative;
      text-align: left;
    }

    .MuiTypography-body1 {
      font-weight: 600;
      margin-bottom: 1rem;
    }

    .MuiButton-contained {
      margin-bottom: 1rem;
    }
  }

  .pop-up {
    //border: 10px solid blue;
    .btn-close-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .btn-close {
        color: $grey800;
        margin-top: -48px;
        margin-right: -27px;
        // position: absolute;
        // top: -50px;
        // left: -48px;
      }
    }
  }

  .subtitles {
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .subtitles-left {
    display: flex;
  }
  .inner-subtitles {
    text-align: left;
    display: flex;
    .icon-container {
      width: 20%;
      align-items: center;
      display: flex;
      flex-direction: row-reverse;
      img {
        width: unset;
        margin-right: 5px;
        margin-bottom: 1.5rem;
      }
    }
    .warning {
      color: #dca900;
    }
  }

  .checkbox {
    //border: 1px solid blue;
  }
  .checkbox-label {
    //border: 1px solid black;
    //margin-left: 10px;
    width: 100%;
    .MuiTypography-body1 {
      //border: 1px solid blue;
    }
    .MuiFormControlLabel-label {
      //border: 1px solid red;
      font-size: 0.8rem;
      margin-top: 14px;
    }
  }
  .show-map-btn {
    margin-bottom: 10px;
    border: 0.12rem solid #00000033 !important;
    text-transform: none;
  }
}
